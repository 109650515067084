<template>
  <f7-page ptr @ptr:refresh="getAccessList">
    <navbar title="Åtkomst" back-link="Tillbaka"></navbar>
    <f7-block>
      <f7-list accordion-list>
        <f7-list-item accordion-item v-for="user in access" :key="user.id" header="Email" :title="user.email">
          <f7-icon slot="media" f7="person"></f7-icon>
          <f7-accordion-content>
            <f7-block>
              <f7-list>
                <f7-list-item>
                  <span>Skrivrättigheter</span>
                  <f7-toggle :checked="!!user.writable" color="blue" @toggle:change="giveAccess(user.email, { writable: $event })"></f7-toggle>
                </f7-list-item>
                <f7-list-item>
                  <span>Larmmottagare</span>
                  <f7-toggle :checked="!!user.alarmReceiver" color="blue" @toggle:change="giveAccess(user.email, { alarmReceiver: $event })"></f7-toggle>
                </f7-list-item>
                <f7-list-button title="Ta bort" color="red" @click="removeAccess(user.email)"></f7-list-button>
              </f7-list>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-block>
    <f7-block>
      <f7-block>
        <f7-button fill raised popup-open="#give-access">
          <f7-icon material="add" size="14"></f7-icon> Lägg till användare
        </f7-button>
      </f7-block>
    </f7-block>
    <!-- Popup -->
    <f7-popup id="give-access">
      <f7-view>
        <f7-page>
          <f7-navbar title="Lägg till användare">
            <f7-nav-right>
              <f7-link popup-close>Stäng</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-list form>
            <f7-list-input label="Epost" type="email" autofocus placeholder="Ange användarens epost" :value="newAccess.email" @input="newAccess.email = $event.target.value">
            </f7-list-input>
            <f7-list-item>
              <span>Skrivrättigheter</span>
              <f7-toggle :checked="newAccess.writable" @toggle:change="newAccess.writable = $event"></f7-toggle>
            </f7-list-item>
            <f7-list-item>
              <span>Larmmottagare</span>
              <f7-toggle :checked="newAccess.alarmReceiver" @toggle:change="newAccess.alarmReceiver = $event"></f7-toggle>
            </f7-list-item>
            <f7-block>
              <f7-button fill raised @click="giveAccess" :disabled="saving">{{ submitText }}</f7-button>
            </f7-block>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<script>

let evtSource 
export default {
  data() {
    return {
      access: [],
      newAccess: {
        email: '',
        writable: false,
        alarmReceiver: false
      },
      saving: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    unitId() {
      return this.$f7route.params.unitId
    },
    submitText() {
      return !this.saving ? 'Lägg till' : 'Sparar..'
    }
  },
  mounted() {
    this.getAccessList()
  },
  methods: {
    getAccessList(event, done) {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      this.$request.json(`/api/units/${this.unitId}/access`, (data, status) => {
        app.dialog.close()
        if (done) done()
        if (data.access) {
          this.access = data.access
        }
      }, (xhr, status) => {
        app.dialog.close()
        if (done) done()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        } else {
          app.dialog.alert('Något gick fel')
        }
      })
    },
    giveAccess(email, update) {
      const instance = this
      const app = this.$f7
      instance.saving = true
      let data
      let method
      if (!email || !update) {
        method = 'POST'
        data = {
          email: this.newAccess.email,
          writable: this.newAccess.writable ? 1 : 0,
          alarmReceiver: this.newAccess.alarmReceiver ? 1 : 0
        }
      } else {
        method = 'PUT'
        data = {
          email: email
        }
        if (update.hasOwnProperty('writable')) data.writable = update.writable ? 1 : 0
        if (update.hasOwnProperty('alarmReceiver')) data.alarmReceiver = update.alarmReceiver ? 1 : 0
      }
      this.$request({
        url: `/api/units/${this.unitId}/access`,
        method,
        dataType: 'json',
        data,
        success(data, status) {
          if (data.access) {
            instance.access = data.access
          }
          app.popup.close()
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          } else if (status === 404) {
            app.dialog.alert('Användaren finns inte')
          } else {
            app.dialog.alert('Något gick fel')
          }
        },
        complete(xhr, status) {
          instance.saving = false
        }
      })
    },
    removeAccess(email) {
      const instance = this
      const app = this.$f7
      app.dialog.confirm('Vill du verkligen ta bort användaren?', 'Bekräfta', () => {
        this.$request({
          url: `/api/units/${this.unitId}/access`,
          method: 'DELETE',
          dataType: 'json',
          data: {
            email
          },
          success(data, status) {
            if (data.access) {
              instance.access = data.access
            }
          },
          error(xhr, status) {
            if (status === 401) {
              app.dialog.alert('Du har inte åtkomst')
            } else {
              app.dialog.alert('Något gick fel')
            }
          }
        })
      })
    }
  }
}

</script>