function displayValue (parameter) {
  if (!parameter.hasOwnProperty('value')) {
    return ''
  }

  if (parameter.enum) {
    return parameter.enum[parameter.value]
  }

  if (parameter.unit) {
    return `${parameter.value} ${parameter.unit}`
  }

  return parameter.value
}

export { displayValue }
