<template>
  <f7-page>
    <navbar :title="device.name" back-link="Tillbaka"></navbar>
    <f7-block>
      <f7-list>
        <f7-list-item header="Namn" :title="device.name"></f7-list-item>
        <f7-list-item header="Modell" :title="device.model"></f7-list-item>
        <f7-list-item header="Version" :title="device.minVersion + '-' + device.maxVersion"></f7-list-item>
        <f7-list-item header="Type" :title="device.busType"></f7-list-item>
      </f7-list>
    </f7-block>
    <f7-block>
      <f7-button fill raised popup-open="#edit-device">
        <f7-icon material="create" size="14"></f7-icon> Redigera
      </f7-button>
    </f7-block>
    <f7-block>
      <f7-button fill raised :href="(`/modeller/${device.id}/parametrar`)">
        <f7-icon material="settings" size="14"></f7-icon> Parametrar
      </f7-button>
    </f7-block>
    <!-- Popup -->
    <f7-popup id="edit-device">
      <f7-view>
        <f7-page>
          <f7-navbar title="Redigera modell">
            <f7-nav-right>
              <f7-link popup-close>Stäng</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-list form>
            <f7-list-input label="Meta" type="textarea" placeholder="Meta" :value="prettyMeta" @change="device.meta = JSON.parse($event.target.value)">
            </f7-list-input>
            <f7-block>
              <f7-button fill raised @click="updateDevice" :disabled="saving">{{ submitText }}</f7-button>
            </f7-block>
          </f7-list>
          <f7-block>
            <f7-button fill raised @click="resetJSON">Reset JSON</f7-button>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<script>

let evtSource 
export default {
  data() {
    return {
      device: {},
      saving: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    deviceId() {
      return this.$f7route.params.deviceId
    },
    submitText() {
      return !this.saving ? 'Spara' : 'Sparar..'
    },
    prettyMeta() {
      if (this.device.meta) {
        return JSON.stringify(this.device.meta, null, 2)
      }
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this
    app.dialog.preloader('Laddar..')

    this.$request.json(`/api/devices/${this.deviceId}`, (data, status) => {
      app.dialog.close()
      if (data.device) {
        this.device = data.device
        app.data.devices[this.deviceId] = data.device
      }
    }, (xhr, status) => {
      app.dialog.close()
      if (status === 401) {
        app.dialog.alert('Du har inte åtkomst')
      }
    })
  },
  methods: {
    resetJSON() {
      this.$request.json(`/api/devices/${this.deviceId}/resetjson`, (data, status) => {
        console.log(status)
      })
    },
    updateDevice() {
      const instance = this
      const app = this.$f7
      instance.saving = true

      const updatedDevice = {
        meta: this.device.meta
      }

      this.$request({
        url: `/api/devices/${this.deviceId}`,
        method: 'PUT',
        dataType: 'json',
        data: updatedDevice,
        success(data, status) {
          instance.device = data.device
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          }
        },
        complete(xhr, status) {
          instance.saving = false
          app.popup.close()
        }
      })
    }
  }
}

</script>
<style>
 textarea {
   white-space:pre;
 }
</style>
