<template>
  <f7-page>
    <navbar title="Parametrar" back-link="Tillbaka"></navbar>
    <f7-block>
      <div class="data-table" v-if="groupParameters.length">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Namn</th>
              <th class="numeric-cell">Log</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="parameter in groupParameters" :key="parameter.modbus">
              <td class="label-cell">{{parameter.name}}</td>
              <td class="numeric-cell">
                <f7-toggle :checked="!!parameter.log" @toggle:change="setLog(parameter, $event)"></f7-toggle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else>Använd menyn längst ner för att välja parametergrupp.</p>
    </f7-block>
    <div class="bottom-bar-spacer"></div>
    <div class="bottom-bar">
    <f7-list>
      <f7-list-item title="Parametergrupp" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
        <select name="group" :value="selectedGroup" @change="selectGroup($event.target.value)">
          <option value="0">Välj grupp</option>
          <option v-for="group in groups" :key="group.id" :value="group.groupIndex">{{group.name}}</option>
        </select>
      </f7-list-item>
    </f7-list>
    </div>
  </f7-page>
</template>

<script>
let evtSource 
export default {
  data() {
    return {
      groups: [],
      selectedGroup: '0'
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    device() {
      return this.$f7.data.devices[this.deviceId] || {}
    },
    deviceId() {
      return this.$f7route.params.deviceId
    },
    groupParameters() {
      const group = this.groups.find(group => group.groupIndex == this.selectedGroup)
      if (group) {
        return group.parameters
      }
      return []
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this

    this.groups = this.device.groups.filter(group => group.groupIndex !== 100)
  },
  methods: {
    selectGroup(groupIndex) {
      const app = this.$f7
      this.selectedGroup = groupIndex
    },
    setLog(parameter, log) {
      // this.$f7.preloader.show()
      const app = this.$f7
      app.dialog.preloader('Laddar..')
      const updatedParameter = {
        log: log ? 1 : 0
      }
      this.$request({
        url: `/api/devices/${this.deviceId}/${parameter.id}`,
        method: 'PUT',
        dataType: 'json',
        data: updatedParameter,
        success(data, status) {
          // app.dialog.close()
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          }
        },
        complete(xhr, status) {
          app.dialog.close()
        }
      })
    }
  }
}
</script>
<style scoped>
  .bottom-bar-spacer {
    padding-top: 42px;
  }

  .bottom-bar {
    position: fixed;
    width: 100%;
    bottom: 48px !important; 
    top: auto !important; 
    background: #fff;
    color: #000;
    z-index: 20;
  }

  .bottom-bar .list {
    margin: 0;
  }

  .md .toolbar-bottom-md ~ .page-content {
    padding-bottom: 77px !important;
  }

  .md .bottom-bar:after {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 100%;
    height: 10px;
    top: auto;
    pointer-events: none;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.3)), color-stop(40%, rgba(0, 0, 0, 0.1)), color-stop(50%, rgba(0, 0, 0, 0.05)), color-stop(80%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
  }
</style>