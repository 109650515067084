<template>
  <f7-page class="units" ptr @ptr:refresh="refreshUnits" @page:beforein="getUnits">
    <navbar title="Mina enheter">
      <f7-nav-right color="white">
        <f7-link popup-open="#filter-units">
          <f7-icon f7="filter"/>
        </f7-link>
      </f7-nav-right>
    </navbar>
    <f7-searchbar
      id="units-searchbar"
      v-if="units.length"
      :disable-button="false"
      disable-link-text="Avbryt"
      placeholder="Sök bland enheter"
      :clear-button="true"
      @input="filterUnits"
    ></f7-searchbar>
    <f7-list media-list>
      <f7-list-item v-if="!units.length">Du har inga enheter ännu.</f7-list-item>
      <f7-list-item
        v-for="unit in filteredUnits"
        :key="unit.id"
        :title="unit.name || 'Ny enhet'"
        :subtitle="unit.customer" 
        :link="(`/enheter/${unit.id}`)"
        :after="unit.value || '-'"
      >
        <f7-icon slot="media" size="34" style="height: 34px;" :material="iconType(unit)" :text-color="iconColor(unit)"></f7-icon>
      </f7-list-item>
    </f7-list>
    <f7-popup id="filter-units">
      <f7-view>
        <f7-page>
          <f7-navbar title="Filtrera enheter">
            <f7-nav-right>
              <f7-link popup-close>Stäng</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-list>
            <f7-list-group>
              <f7-list-item group-title>Kunder</f7-list-item>
              <f7-list-item v-for="item in customers" :key="item" @click="setFilter(item)" link="#">{{ item }}</f7-list-item>
            </f7-list-group>
            <f7-list-group>
              <f7-list-item group-title>Anläggningar</f7-list-item>
              <f7-list-item v-for="item in sites" :key="item" @click="setFilter(item)" link="#">{{ item }}</f7-list-item>
            </f7-list-group>
            <f7-list-group>
              <f7-list-item group-title>Typer</f7-list-item>
              <f7-list-item v-for="item in types" :key="item" @click="setFilter(item)" link="#">{{ item }}</f7-list-item>
            </f7-list-group>
          </f7-list>
          <!-- <f7-list form>
            <f7-list-input
              label="Kund"
              type="select"
            >
              <option value="Välj kund" disabled selected>Välj kund</option>
              <option v-for="customer in customers" :key="customer" :value="customer">{{ customer }}</option>
            </f7-list-input>
            <f7-list-input
              label="Typ"
              type="select"
            >
              <option value="Välj typ" disabled selected>Välj typ</option>
              <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
            </f7-list-input>
            <f7-list-input label="Stad" type="text" placeholder="Stad"></f7-list-input>
          </f7-list> -->
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<script>
function getListOfUniqueKeyValues(arrayOfObjects, key) {
  return arrayOfObjects.reduce((list, item) => {
    if (item[key] && !list.includes(item[key])) {
      list.push(item[key])
    }

    return list
  }, []).sort()
}

export default {
  data() {
    return {
      units: [],
      nameFilter: ''
    }
  },
  computed: {
    customers() {
      return getListOfUniqueKeyValues(this.units, 'customer')
    },
    types() {
      return getListOfUniqueKeyValues(this.units, 'type')
    },
    sites() {
      return getListOfUniqueKeyValues(this.units, 'site')
    },
    filteredUnits() {
      if (!this.nameFilter) {
        return this.units
      }

      const pattern = new RegExp(this.nameFilter, 'gi')
      const fields = ['name', 'customer', 'site', 'type']

      return this.units.filter((unit) => {
        for (const key of fields) {
          if (unit[key] && unit[key].match(pattern)) {
            return true
          }
        }
      })
    }
  },
  mounted() {
    console.log('Units mounted!')
  },
  methods: {
    setFilter(newValue) {
      // searchbar.search throws error for some reason, so I had to wrap it in trycatch
      try {
        this.$f7.searchbar.search('#units-searchbar', newValue)
      } catch {
        
      }
      this.$f7.popup.close('#filter-units')
    },
    iconType(unit) {
      if (unit.online && unit.deviceOnline) {
        return 'sentiment_very_satisfied'
      } else if (unit.online) {
        return 'sentiment_dissatisfied'
      } else {
        return 'sentiment_very_dissatisfied'
      }
    },
    iconColor(unit) {
      if (unit.online && unit.deviceOnline) {
        return 'green'
      } else if (unit.online) {
        return 'orange'
      } else {
        return 'red'
      }
    },
    filterUnits(event) {
      this.nameFilter = event.target.value
    },
    refreshUnits(event, done) {
      this.getUnits().then(() => {
        done()
      })
    },
    onEnter() {
      if (!app.data.unitsList.length) {
        app.dialog.preloader('Laddar..')
        this.getUnits()
      } else {
        instance.units = app.data.unitsList
        this.getUnits()
      }
    },
    getUnits() {
      return new Promise((resolve, reject) => {
        const instance = this
        const app = this.$f7
        this.$request.json('/api/units', (data, status) => {
          if (data.units) {
            app.data.unitsList = data.units
            this.units = data.units
            this.getValues()
            resolve()
          }
          app.dialog.close()
        }, (data, status) => {
          setTimeout(() => {
            app.dialog.close()
            this.$f7router.navigate('/login/', { pushState: false, context: { redirectedFrom: this.$f7route.url } })
            reject()
          }, 300)
        })
      })
    },
    getValues() {
      for (let i = 0; i < this.units.length; i++) {
        this.$request.json(`/api/units/${this.units[i].id}/summary`, (data, status) => {
          if (data.summary && data.summary.value) {
            this.$set(this.units[i], 'value', parseFloat(data.summary.value).toFixed(1))
          }
        })
      }
    }
  }
}
</script>

<style>
  .md .units .list .item-after {
      font-size: 28px !important;
      line-height: 1.0 !important;
  }
</style>