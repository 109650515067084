<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
  <f7-login-screen-title>Logga in</f7-login-screen-title>
  <form @submit="login">
   <f7-list>
      <f7-list-input 
          label="Epost"
          name="username" 
          autocomplete="username" 
          placeholder="exempel@exempel.com" 
          type="email" 
          :value="username" 
          @input="username = $event.target.value"
          @blur="validateForm" 
          autofocus 
          required 
          error-message="Ange en giltig epostadress"
        >
      </f7-list-input>
      <f7-list-input 
          label="Lösenord"
          name="password" 
          autocomplete="current-password" 
          :type="passwordInputType" 
          minlength="5" 
          placeholder="" 
          @blur="validateForm"
          :value="password" 
          @input="password = $event.target.value"
          error-message="Ange minst 5 tecken" 
          required 
        >
      </f7-list-input>
      <f7-block>
        <button class="button button-fill button-raised" :class="{disabled: loggingIn}" type="submit" :disabled="loggingIn">{{ loginText}}</button>
      </f7-block>
    </f7-list>
    </form>
    <f7-block>
      <f7-button href="/">Tillbaka</f7-button> 
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        passwordMask: true,
        loggingIn: false
      }
    },
    computed: {
      loginText() {
        return this.loggingIn ? 'Loggar in..' : 'Logga in'
      },
      passwordInputType() {
        return this.passwordMask ? 'password' : 'text'
      }
    },
    mounted() {
      const unmaskButton = document.createElement("span")
      unmaskButton.classList.add('input-unmask-button')
      unmaskButton.innerHTML = 'remove_red_eye'
      unmaskButton.addEventListener('click', this.togglePasswordMask)
      document.querySelector('input[name="password"]').parentElement.appendChild(unmaskButton)
    },
    methods: {
      validateForm(event) {
        this.$f7.input.validate(event.target)
      },
      togglePasswordMask() {
        this.passwordMask = false
        setTimeout(() => {
          this.passwordMask = true
        }, 4000)
      },
      login(event) {
        event.preventDefault()
        const instance = this
        const app = this.$f7
        const router = app.views.main.router
        const route = this.$f7route
        instance.loggingIn = true
        // const router = this.$f7router

        this.$request.post('/api/auth/login', {
          username: this.username,
          password: this.password
        }, (data, status) => {
          this.$f7.emit('loggedIn', true)
          instance.loggingIn = false
          if (status === 200) {
            // app.dialog.alert('Välkommen!', 'Lyckades!')
            data = JSON.parse(data)
            this.user = data.user
            localStorage.setItem('user', JSON.stringify(data.user))
            app.data.user = data.user
            
            if (route.context && route.context.redirectedFrom) {
              router.navigate(route.context.redirectedFrom)
            } else {
              router.navigate('/enheter/')
            }
          }
        }, (data, status) => {
          instance.loggingIn = false
          if (status === 401) {
            app.dialog.alert('Fel användarnamn eller lösenord!', 'Hoppsan!')
          } else {
            app.dialog.alert('Någonting gick fel', 'Hoppsan!')
          }
        })
      }
    }
  }
</script>