<template>
  <f7-page @page:beforein="beforeIn" @page:beforeout="beforeOut">
    <navbar :title="unit.name || 'Ny enhet'" back-link="Tillbaka"></navbar>
    <f7-block strong>
      <div class="data-table" v-if="parameters.length">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Namn</th>
              <th class="numeric-cell">Värde</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="parameter in parameters" :key="parameter.modbus">
              <td class="label-cell">
                {{parameter.name}}
              </td>
              <td class="numeric-cell">
                {{ displayValue(parameter) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </f7-block>
  </f7-page>
</template>

<script>
import unitMixin from '../mixins/unit'
import { displayValue } from './unit-helpers'

let evtSource 
export default {
  mixins: [unitMixin],
  data() {
    return {
      parameters: [],
      fetchTimer: null
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
  },
  destroyed() {
    
  },
  methods: {
    beforeIn() {
      console.log('Before in - Live')
      const app = this.$f7
      const instance = this

      if (!this.unit.deviceId) {
        this.getUnit(null, this.getDevice)
      }

      // console.log(app.data.devices)
      // if (!app.data.devices[this.unit.deviceId]) {
      //   this.getDevice()
      // } else {
      //   instance.parameters = app.data.devices[this.unit.deviceId].loggedParameters
      //   this.resumePolling()
      // }
    },
    mounted() {
      console.log('Unit live mounted!')
    },
    beforeOut() {
      console.log('Before out - Live')
      clearInterval(this.fetchTimer)
    },
    displayValue,
    getDevice () {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      this.$request.json(`/api/devices/${this.unit.deviceId}`, (data, status) => {
        app.dialog.close()
        if (data.device && data.device.groups) {
          app.data.devices[this.unit.deviceId] = data.device
          instance.parameters = data.device.loggedParameters
          this.resumePolling()
        }
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    },
    pausePolling() {
      clearInterval(this.fetchTimer)
    },
    resumePolling() {
      this.getValues()
      this.fetchTimer = setInterval(this.getValues, 5000)
    },
    getValues() {
      const app = this.$f7
      this.$request.json(`/api/units/${this.unitId}/groups/0`, (data, status) => {
        if (data.group) {
          for (const key in data.group) {
            const parameterIndex = this.parameters.findIndex(i => i.name === key)
            if (parameterIndex >= 0) {
              this.$set(this.parameters[parameterIndex], 'value', data.group[key])
            }
          }
        }
        app.preloader.hide()
      }, (xhr, status) => {
        app.preloader.hide()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    }
  },
  beforeDestroy() {
    const app = this.$f7
    // evtSource.close()
    app.dialog.close()
  }
}
</script>