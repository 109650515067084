<template>
  <f7-page ptr @ptr:refresh="getFirmwares">
    <navbar title="Firmwares" back-link="Tillbaka"></navbar>
    <f7-block>
      <f7-list>
        <f7-list-item
          header="ID: 0"
          title="Automatisk version"
          link="#"
          @click="selectFirmware(0)"
        ></f7-list-item>
        <f7-list-item
          v-for="firmware in firmwares"
          :key="firmware.id"
          :header="(`ID: ${firmware.id}`)"
          :title="(`Version: ${firmware.version} ${firmware.beta ? '(beta)': ''}`)"
          link="#"
          @click="selectFirmware(firmware.id)"
        ></f7-list-item>
      </f7-list>
    </f7-block>
  </f7-page>
</template>

<script>

let evtSource 
export default {
  data() {
    return {
      firmwares: [],
      saving: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    unitId() {
      return this.$f7route.params.unitId
    },
    submitText() {
      return !this.saving ? 'Spara' : 'Sparar..'
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this
    this.getFirmwares()
  },
  methods: {
    getFirmwares(event, done) {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      this.$request.json(`/api/firmwares`, (data, status) => {
        app.dialog.close()
        if (data.firmwares) {
          this.firmwares = data.firmwares
        }
        if (done) done()
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
        if (done) done()
      })
    },
    updateUnitFirmware() {
      const app = this.$f7
      this.$request.get(`/api/units/${this.unitId}/updatefw`, (data, status) => {
        app.dialog.alert('Enheten uppdateras!')
      })
    },
    selectFirmware(firmwareId) {
      const instance = this
      const app = this.$f7
      instance.saving = true

      const updatedUnit = {
        forcedFirmware: firmwareId
      }

      this.$request({
        url: `/api/units/${this.unitId}`,
        method: 'PUT',
        dataType: 'json',
        data: updatedUnit,
        success(data, status) {
          instance.updateUnitFirmware()
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          } else {
            app.dialog.alert('Någonting gick fel', 'Hoppsan!')
          }
        },
        complete(xhr, status) {
          instance.saving = false
        }
      })
    }
  }
}

</script>

<style>
</style>