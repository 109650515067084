export default {
  data() {
    return {
      unit: {
        customer: null,
        deviceId: null,
        deviceOnline: null,
        id: null,
        name: null,
        online: null,
        site: null,
        type: null
      }
    }
  },
  computed: {
    unitId() {
      return this.$f7route.params.unitId
    }
  },
  methods: {
    getUnit(event, done) {
      const app = this.$f7
      const instance = this
      if (this.$f7.data.units[this.unitId]) {
        this.unit = this.$f7.data.units[this.unitId]
      } else {
        app.dialog.preloader('Laddar..')
      }

      this.$request.json(`/api/units/${this.unitId}`, (data, status) => {
        app.dialog.close()
        if (data.unit) {
          this.unit = data.unit
          app.data.units[this.unitId] = data.unit
        }
        if (done) done()
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
        if (done) done()
      })
    },
  }
}