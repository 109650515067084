<template>
  <f7-page ptr @ptr:refresh="getUsers">
    <navbar title="Användare" back-link="Tillbaka">
      <f7-nav-right v-if="isAdmin">
        <f7-link panel-open="admin">
          <f7-icon f7="gear"/>
        </f7-link>
      </f7-nav-right>
    </navbar>
    <f7-list>
      <f7-list-item v-for="user in users" :key="user.id" :title="user.email" :link="(`/anvandare/${user.id}`)" swipeout>
        <f7-icon slot="media" f7="person"></f7-icon>
        <f7-swipeout-actions right>
          <f7-swipeout-button color="red" @click="deleteUser(user.id)" confirm-text="Vill du verkligen ta bort användaren?">Radera</f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      users: []
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    }
  },
  mounted() {
    const app = this.$f7

    this.getUsers()
  },
  methods: {
    getUsers(event, done) {
      const app = this.$f7
      app.dialog.preloader('Laddar..')

      this.$request.json('/api/users', (data, status) => {
        app.dialog.close()
        if (data.users) {
          this.users = data.users
          app.data.users = data.users
        }
        if (done) done()
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
        if (done) done()
      })
    },
    deleteUser(userId) {
      const instance = this
      console.log('Will delete')
      this.$request({
        url: `/api/users/${userId}`,
        method: 'DELETE',
        statusCode: {
          200(xhr) {
            const userIndex = instance.users.findIndex(user => user.id === userId)
            instance.users.splice(userIndex, 1)
          },
          404(xhr) {
            console.log('Failed!')
          }
        }
      })
    }
  }
}
</script>