import HomePage from '../pages/home.vue'
import UsersPage from '../pages/users.vue'
import UserDetailsPage from '../pages/user-details.vue'
import DevicesPage from '../pages/devices.vue'
import DeviceDetailsPage from '../pages/device-details.vue'
import DeviceParametersPage from '../pages/device-parameters.vue'
import UnitsPage from '../pages/units.vue'
import UnitDetailsPage from '../pages/unit-details.vue'
import UnitFirmwarePage from '../pages/unit-firmware.vue'
import UnitLivePage from '../pages/unit-live.vue'
import UnitParametersPage from '../pages/unit-parameters.vue'
// const UnitChartPage = () => import('../pages/unit-chart.vue')
import UnitChartPage from '../pages/unit-chart.vue'
import UnitAccessPage from '../pages/unit-access.vue'
import UnitLogsPage from '../pages/unit-logs.vue'
import AlarmsPage from '../pages/alarms.vue'
import ProfilePage from '../pages/profile.vue'
import NotFoundPage from '../pages/not-found.vue'
import LoginPage from '../pages/login.vue'
import RegisterPage from '../pages/register.vue'

import PanelAdminPage from '../pages/panel-admin.vue'

export default [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/login/',
    component: LoginPage
  },
  {
    path: '/register/',
    component: RegisterPage
  },
  {
    path: '/panel-admin/',
    component: PanelAdminPage
  },
  {
    path: '/enheter/',
    component: UnitsPage
  },
  {
    path: '/anvandare/',
    component: UsersPage
  },
  {
    path: '/anvandare/:userId',
    component: UserDetailsPage
  },
  {
    path: '/enheter/:unitId',
    component: UnitDetailsPage
  },
  {
    path: '/enheter/:unitId/live',
    component: UnitLivePage
  },
  {
    path: '/enheter/:unitId/firmware',
    component: UnitFirmwarePage
  },
  {
    path: '/enheter/:unitId/parametrar',
    component: UnitParametersPage
  },
  {
    path: '/enheter/:unitId/historik',
    component: UnitChartPage
  },
  {
    path: '/enheter/:unitId/access',
    component: UnitAccessPage
  },
  {
    path: '/enheter/:unitId/logg',
    component: UnitLogsPage
  },
  {
    path: '/modeller/',
    component: DevicesPage
  },
  {
    path: '/modeller/:deviceId',
    component: DeviceDetailsPage
  },
  {
    path: '/modeller/:deviceId/parametrar',
    component: DeviceParametersPage
  },
  {
    path: '/larm/',
    component: AlarmsPage
  },
  {
    path: '/profil/',
    component: ProfilePage
  },
  {
    path: '(.*)',
    component: NotFoundPage
  }
]
