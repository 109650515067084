<template>
  <f7-page>
    <navbar title="Din profil" back-link="Tillbaka">
      <f7-nav-right v-if="isAdmin">
        <f7-link panel-open="admin">
          <f7-icon f7="gear"/>
        </f7-link>
      </f7-nav-right>
    </navbar>
    <template v-if="user.email">
      <f7-list form>
        <f7-list-input label="Förnamn" type="text" placeholder="Förnamn" :value="user.firstname" @input="user.firstname = $event.target.value"></f7-list-input>
        <f7-list-input label="Efternamn" type="text" placeholder="Efternamn" :value="user.lastname" @input="user.lastname = $event.target.value"></f7-list-input>
        <f7-block>
          <f7-button fill raised @click="updateUser" :disabled="saving">{{ submitText }}</f7-button>
        </f7-block>
      </f7-list>
      <f7-block >
        <f7-button fill raised @click="createToken">Skapa nyckel</f7-button>
      </f7-block>
      <f7-block>
        <f7-button @click="logout" :disabled="loggingOut">Logga ut</f7-button>
      </f7-block>
    </template>
    <template v-else>
      <f7-block>
        <p>Du är inte inloggad.</p>
      </f7-block>
      <f7-block >
        <f7-button fill raised href="/login/">Logga in</f7-button>
      </f7-block>
    </template>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      saving: false,
      loggingOut: false
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    submitText() {
      return !this.saving ? 'Spara' : 'Sparar..'
    },
    user() {
      return this.$f7.data.user 
    }
  },
  mounted() {
    // console.log(this.$f7.data)
    this.$request.json(`/api/users/me`, (data, status) => {
      if (data.user) {
        // this.user = data.user
      }
    })
  },
  methods: {
    createToken() {
      const app = this.$f7
      this.$request.json(`/api/users/${this.user.id}/token`, (data, status) => {
        app.dialog.alert(data.token.token, 'Giltig i 1 timme')
      })
    },
    logout() {
      this.$request.get('/api/auth/logout')
      this.$f7.data.user = {}
      localStorage.removeItem('user')
      this.$f7.emit('loggedIn', false)
      this.$f7router.navigate('/')
    },
    updateUser() {
      const instance = this
      const app = this.$f7
      instance.saving = true

      const userId = this.user.id

      const updatedUser = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
      }

      this.$request({
        url: `/api/users/${userId}`,
        method: 'PUT',
        dataType: 'json',
        data: updatedUser,
        success(data, status) {
          app.data.user = data.user
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          }
        },
        complete(xhr, status) {
          instance.saving = false
        }
      })
    }
  }
}
</script>