<template>
  <f7-page>
    <navbar title="Mina enheter" back-link="Tillbaka"></navbar>
    <f7-toolbar top bg-color="primary">
      <f7-link style="flex-grow: 1" tab-link="#active-alarms" tab-link-active>Aktiva</f7-link>
      <f7-link style="flex-grow: 1" tab-link="#cleared-alarms">Historiska</f7-link>
    </f7-toolbar>
    <f7-tabs swipeable>
      <f7-tab id="active-alarms" tab-active>
        <f7-list media-list>
          <f7-list-item v-for="alarm in activeAlarms" :key="alarm.id" :title="alarm.name" :subtitle="unitName(alarm.unitId)" :after="alarm.createdAt | datetime"></f7-list-item>
          <f7-list-item v-if="!activeAlarms.length" title="Inga aktiva larm"></f7-list-item>
        </f7-list>
      </f7-tab>
      <f7-tab id="cleared-alarms">
        <f7-list media-list>
          <f7-list-item v-for="alarm in clearedAlarms" :key="alarm.id" :title="alarm.name" :subtitle="unitName(alarm.unitId)" :after="alarm.createdAt | datetime"></f7-list-item>
          <f7-list-item v-if="!clearedAlarms.length" title="Inga historiska larm"></f7-list-item>
        </f7-list>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      alarms: [],
      activeAlarms: [],
      clearedAlarms: []
    }
  },
  filters: {
    datetime (date) {
      return (new Date(date)).toLocaleString()
    }
  },
  mounted() {
    this.getAlarms()
  
    setInterval(() => {
      this.getAlarms()
    }, 5000)
  },
  methods: {
    getAlarms() {
      this.activeAlarms = this.$f7.data.activeAlarms || []
      this.clearedAlarms = this.$f7.data.clearedAlarms || []
    },
    unitName (unitId) {
      const app = this.$f7
      const unit = app.data.unitsList.find(unit => unit.id === unitId)
      return unit ? unit.name : unitId 
    }
  }
}
</script>
<style scoped>
  .toolbar-inner .tab-link.tab-link-active {
    font-weight: 600;
  }
  /* .ios .toolbar {
    bottom: 44px;
  }

  .md .navbar + .toolbar:not(.toolbar-bottom-md):not(.messagebar) ~ * .page-content, .md .navbar + .toolbar:not(.toolbar-bottom-md):not(.messagebar) ~ .page-content {
    padding-top: 0px !important;
  }

  .toolbar-inner > a.link {
    width: 100%
  } */
</style>