<template>
  <!-- App -->
  <f7-app :params="f7params">

    <!-- Statusbar -->
    <f7-statusbar></f7-statusbar>

    <!-- Right Panel -->
    <f7-panel right cover>
      <f7-view url="/panel-admin/"></f7-view>
    </f7-panel>

    <!-- Main View -->
    <f7-view id="main-view" url="/" main :push-state="true">
      <toolbar v-if="loggedIn" :alarms="alarmsCount"></toolbar>
    </f7-view>

  </f7-app>
</template>

<script>
// Import Routes
import routes from '../js/routes.js';

export default {
  data() {
    return {
      loggedIn: false,
      user: {},
      username: '',
      password: '',
      alarmsCount: 0,
      // Framework7 parameters here
      f7params: {
        data() {
          return {
            users: [],
            user: {},
            devices: {},
            units: {},
            unitsList: []
          }
        },
        id: 'io.framework7.testapp', // App bundle ID
        name: 'Kylprodukter', // App name
        theme: 'auto', // Automatic theme detection
        routes: routes,
        serviceWorker: {
          path: '/service-worker.js',
        },
      },
    }
  },
  mounted() {
    let alarmFetcher

    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      this.$f7.data.user = user
      this.loggedIn = true

      this.getAlarms()
      alarmFetcher = setInterval(this.getAlarms, 10000)
    }

    this.$f7.on('loggedIn', (isLoggedIn) => {
      this.loggedIn = isLoggedIn
      if (isLoggedIn) {
        this.getAlarms()
        alarmFetcher = setInterval(this.getAlarms, 10000)
      } else {
        clearInterval(alarmFetcher)
      }
    })
  },
  destroyed() {
    clearInterval(alarmFetcher)
  },
  methods: {
    getAlarms() {
      const app = this.$f7
      this.$request.json('/api/units/alarms', (data, status) => {
        if (status === 200) {
          app.data.activeAlarms = data.alarms.active
          this.alarmsCount = data.alarms.active.length
          app.data.clearedAlarms = data.alarms.cleared
        }
      })
    }
  }
}
</script>
<style lang="scss">
  .hidden {
    visibility: hidden;
  }

  #main-view > .page {
    padding-bottom: 48px;
  }
  .canvasjs-chart-toolbar {
    border: 0;
    
    button {
      margin-bottom: 0;
      margin-right: 0;
      background-color: #333;
      border-radius: 0;
    }
  }
  
  .chart-container-wrapper {
    width: 100%;
    height: 400px;
    padding: 15px 10px 15px 10px;
    
    .chart-container {
      width: 100%;
      height: 100%;
    }
  }

  .md .list-button {
    text-align: center;
  }

  .md .item-input-wrap .input-unmask-button {
    bottom: 6px;
    margin-top: 0;
    top: auto;
  }

  .md .input-unmask-button {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }
  .input-with-value ~ .input-unmask-button, .item-input-with-value .input-unmask-button, .input-with-value .input-unmask-button {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .input-unmask-button {
    font-family: 'Material Icons';
    font-size: 19px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 300ms;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    border: none;
    padding: 0;
    margin: 0;
    outline: 0;
    z-index: 1;
    cursor: pointer;
    background: none;
    right: 0;
  }
</style>