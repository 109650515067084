<template>
  <f7-toolbar bg-color="primary" :tabbar=true :bottom-md=true :no-shadow="noShadow">
    <f7-link text-color="white" href="/enheter/">
      <f7-icon f7="menu"/>
    </f7-link>
    <f7-link text-color="white" href="/larm/">
      <f7-icon f7="bell">
        <f7-badge v-if="alarms" color="red">{{ alarms }}</f7-badge>
      </f7-icon>
    </f7-link>
    <f7-link text-color="white" href="/profil/">
      <f7-icon f7="gear"/>
    </f7-link>
  </f7-toolbar>
</template>

<script>
export default {
  props: {
    noShadow: {
      type: Boolean,
      default: false
    },
    alarms: Number
  }
}
</script>