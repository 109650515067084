<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
  <f7-login-screen-title>Skapa konto</f7-login-screen-title>
    <form id="register-form" @submit="register">
      <f7-list>
        <f7-list-input 
            label="Epost"
            name="username" 
            autocomplete="username" 
            placeholder="exempel@exempel.com" 
            type="email" 
            @input="username = $event.target.value" 
            @blur="validateForm" 
            autofocus 
            required 
            error-message="Ange en giltig epostadress"
          >
        </f7-list-input>
        <f7-list-input 
            label="Lösenord"
            name="password" 
            autocomplete="new-password" 
            :type="passwordInputType" 
            minlength="5" 
            placeholder="" 
            :value="password"
            @input="password = $event.target.value" 
            @blur="validateForm" 
            error-message="Ange minst 5 tecken" 
            required
          >
        </f7-list-input>
      </f7-list>
      <f7-block>
        <button class="button button-fill button-raised" :class="{disabled: registering}" type="submit" :disabled="registering">{{ registerText }}</button>
      </f7-block>
    </form>
    <f7-block>
      <f7-button href="/">Tillbaka</f7-button> 
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        passwordMask: true,
        registering: false,
        validForm: false
      }
    },
    computed: {
      registerText() {
        return this.registering ? 'Skapar konto..' : 'Skapa konto'
      },
      passwordInputType() {
        return this.passwordMask ? 'password' : 'text'
      },
      disableSubmit() {
        return this.registering || false
      }
    },
    mounted() {
      const unmaskButton = document.createElement("span")
      unmaskButton.classList.add('input-unmask-button')
      unmaskButton.innerHTML = 'remove_red_eye'
      unmaskButton.addEventListener('click', this.togglePasswordMask)
      document.querySelector('input[name="password"]').parentElement.appendChild(unmaskButton)
    },
    methods: {
      validateForm(event) {
        this.$f7.input.validate(event.target)
      },
      togglePasswordMask() {
        this.passwordMask = false
        setTimeout(() => {
          this.passwordMask = true
        }, 4000)
      },
      register(event) {
        event.preventDefault()
        const instance = this
        const app = this.$f7
        const router = app.views.main.router
        const route = this.$f7route
        // const router = this.$f7router

        app.input.validateInputs('#register-form')
        const formIsValid = document.querySelector('#register-form').checkValidity()

        if (formIsValid) {
          instance.registering = true

          this.$request.post('/api/auth/register', {
            username: this.username,
            password: this.password
          }, (data, status) => {
            instance.registering = false
            if (status === 200) {
              this.$f7.emit('loggedIn', true)
              // app.dialog.alert('Välkommen!', 'Lyckades!')
              data = JSON.parse(data)
              this.user = data.user
              localStorage.setItem('user', JSON.stringify(data.user))
              app.data.user = data.user
              
              router.navigate('/enheter/')
            }
          }, (data, status) => {
            instance.registering = false
            app.dialog.alert('Någonting gick fel', 'Hoppsan!')
          })
        }
      }
    }
  }
</script>
