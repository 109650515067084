<template>
  <f7-page>
    <navbar title="Admin"></navbar>
    <f7-block-title>Enheter</f7-block-title>
    <f7-list>
      <f7-list-item link="/enheter/" title="Enheter" view="#main-view" panel-close></f7-list-item>
      <f7-list-item link="/modeller/" title="Modeller" view="#main-view" panel-close></f7-list-item>
    </f7-list>
    <f7-block-title>Användare</f7-block-title>
    <f7-list>
      <f7-list-item link="/anvandare/" title="Användare" view="#main-view" panel-close></f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
export default {}
</script>