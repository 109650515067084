<template>
  <f7-page ptr @ptr:refresh="getUnitLog">
    <navbar :title="unit.name || 'Ny enhet'" back-link="Tillbaka"></navbar>
    <f7-block>
      <f7-list accordion-list>
        <f7-list-item v-if="!events.length">Det finns inga händelser.</f7-list-item>
        <f7-list-item 
          v-for="event in events" 
          :key="event.id"
          accordion-item
          :title="event.time | formatDateTime"
          :badge="event.event | eventType"
          :badge-color="event.event | eventColor"
        >
          <f7-accordion-content>
            <f7-block>
              <f7-list>
                <f7-list-item v-for="(value, key) in event.details" :key="key" :title="key">
                  <template v-if="isBoolean(value)">
                     <f7-badge :color="value ? 'green' : 'red'">&nbsp;</f7-badge>
                  </template>
                  <template v-else>
                    {{ value }}
                  </template>
                </f7-list-item>
              </f7-list>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-block>
  </f7-page>
</template>

<script>
import unitMixin from '../mixins/unit'

let evtSource 
export default {
  mixins: [unitMixin],
  data() {
    return {
      events: []
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    }
  },
  filters: {
    prettyJSON (string) {
      return string ? JSON.stringify(string, null, 2) : ''
    },
    eventType (event) {
      const events = {
        statusUpdate: 'Status'
      }
      return events[event] || event
    },
    eventColor (event) {
      const colors = {
        statusUpdate: 'blue'
      }
      return colors[event] || 'gray'
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this
    this.getUnit()
    this.getUnitLog()
  },
  beforeDestroy() {
    const app = this.$f7
    app.dialog.close()
  },
  methods: {
    isBoolean(value) {
      return typeof value === 'boolean'
    },
    getUnitLog(event, done) {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      this.$request.json(`/api/units/${this.unitId}/logs`, (data, status) => {
        app.dialog.close()
        if (data.events) {
          this.events = data.events
        }
        if (done) done()
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
        if (done) done()
      })
    }
  }
}
</script>