<template>
  <f7-page @page:beforein="beforeIn" @page:beforeout="beforeOut">
    <navbar :title="unit.name || 'Ny enhet'" back-link="Tillbaka">
      <div v-if="!isAndroid" class="subnavbar">
        <div class="subnavbar-inner">
          <div class="bottom-bar">
            <f7-list>
              <f7-list-item title="Parametergrupp" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                <select name="group" :value="selectedGroup" @change="selectGroup($event.target.value)">
                  <option value="0">Välj grupp</option>
                  <option v-for="group in groups" :key="group.id" :value="group.groupIndex">{{group.name}}</option>
                </select>
              </f7-list-item>
            </f7-list>
          </div>
        </div>
      </div>
    </navbar>
    <f7-block strong>
      <div class="data-table" v-if="groupParameters.length">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Namn</th>
              <th class="numeric-cell">Värde</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="parameter in groupParameters" :key="parameter.modbus">
              <td class="label-cell">{{parameter.name}}</td>
              <td class="numeric-cell" @click="changeParameter(parameter)">
                {{ displayValue(parameter) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else>Använd menyn för att välja parametergrupp.</p>
    </f7-block>
    <div class="bottom-bar-spacer"></div>
    <div v-if="isAndroid" class="bottom-bar">
      <f7-list>
        <f7-list-item title="Parametergrupp" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
          <select name="group" :value="selectedGroup" @change="selectGroup($event.target.value)">
            <option value="0">Välj grupp</option>
            <option v-for="group in groups" :key="group.id" :value="group.groupIndex">{{group.name}}</option>
          </select>
        </f7-list-item>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import unitMixin from '../mixins/unit'
import { displayValue } from './unit-helpers'

let evtSource 
function valuesBetween(min, max) {
  const arr = []
  for (let i = min; i <= max; i++) {
    arr.push(i)
  }
  return arr
}

let handleVisibilityChange
let hidden
let visibilityChange

export default {
  mixins: [unitMixin],
  data() {
    return {
      groups: [],
      selectedGroup: '0',
      isAndroid: '',
      fetchTimer: false
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    groupParameters() {
      const group = this.groups.find(group => group.groupIndex == this.selectedGroup)
      if (group) {
        return group.parameters
      }
      return []
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this

    this.isAndroid = app.theme === 'md'

    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }

    handleVisibilityChange = function handleVisibilityChange() {
      if (document[hidden]) {
        console.log('Pause polling')
        instance.pausePolling()
      } else {
        console.log('Resume polling')
        instance.resumePolling()
      }
    }
  },
  destroyed() {

  },
  methods: {
    beforeIn() {
      console.log('Before in - Parameters')
      const app = this.$f7
      const instance = this
      console.log(this.unit)
      if (!this.unit.deviceId) {
        this.getUnit(null, this.getDevice)
      }

      // console.log(app.data.devices)
      // if (!app.data.devices[this.unit.deviceId]) {
      //   this.getDevice()
      // } else {
      //   instance.groups = app.data.devices[this.unit.deviceId].groups
      //   // console.log(instance.groups)
      // }

      if (typeof document.addEventListener !== "undefined" && hidden !== undefined) {
        document.addEventListener(visibilityChange, handleVisibilityChange, false)
      }
    },
    beforeOut() {
      console.log('Before out - Parameters')
      this.pausePolling()

      if (typeof document.addEventListener !== "undefined" && hidden !== undefined) {
        document.removeEventListener(visibilityChange, handleVisibilityChange, false)
      }
    },
    displayValue,
    getDevice () {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      this.$request.json(`/api/devices/${this.unit.deviceId || '0'}`, (data, status) => {
        app.dialog.close()
        if (data.device && data.device.groups) {
          app.data.devices[this.unit.deviceId] = data.device
          instance.groups = data.device.groups
          // instance.selectedGroup = instance.groups[0].groupIndex
        }
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    },
    pausePolling() {
      clearInterval(this.fetchTimer)
    },
    resumePolling() {
      if (this.selectedGroup) {
        this.getValues()
        this.fetchTimer = setInterval(this.getValues, 7000)
      }
    },
    selectGroup(groupIndex) {
      this.pausePolling()

      const app = this.$f7
      app.preloader.show('white')
      this.selectedGroup = groupIndex

      this.resumePolling()
    },
    getValues() {
      const app = this.$f7
      this.$request.json(`/api/units/${this.unitId}/groups/${this.selectedGroup}`, (data, status) => {
        if (data.group) {
          const group = this.groups.find(group => group.groupIndex == this.selectedGroup)
          for (const key in data.group) {
            const parameterIndex = group.parameters.findIndex(i => i.name === key)
            if (parameterIndex >= 0) {
              this.$set(group.parameters[parameterIndex], 'value', data.group[key])
            }
          }
        }
        app.preloader.hide()
      }, (xhr, status) => {
        app.preloader.hide()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    },
    changeParameter(parameter) {
      const app = this.$f7
      if (parameter.writable) {
        this.createSheet(parameter)
      }
    },
    setParameter(parameter, value) {
      const app = this.$f7
      app.preloader.show()
      const joinedValue = value.join('')
      this.$request.json(`/api/units/${this.unitId}/pnu/${parameter.modbus}/${joinedValue}`, (data, status) => {
        app.preloader.hide()
        if (data.result) {
          parameter.value = parseFloat(value.join('.'))
        }
      }, (xhr, status) => {
        app.preloader.hide()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    },
    createSheet(parameter) {
      console.log(parameter)
      let value
      let cols
      if (parameter.enum) {
        value = [this.displayValue(parameter)]
        cols = [
          {
            values: Object.keys(parameter.enum),
            displayValues: Object.values(parameter.enum)
          }
        ]
      } else if (parameter.multiplier === 1) {
        value = [parseFloat(parameter.value)]
        cols = [
          {
            values: valuesBetween(parameter.minValue, parameter.maxValue)
          }
        ]
      } else if (parameter.multiplier === 0.1) {
        const parts = parseFloat(parameter.value).toFixed(1).split('.')
        value = parts
        cols = [
          {
            values: valuesBetween(Math.floor(parameter.minValue/10), Math.floor(parameter.maxValue/10))
          },{
            divider: true,
            content: '.'
          },
          {
            textAlign: 'left',
            values: valuesBetween(0, 9)
          }
        ]
      }

      const instance = this;
      const $ = instance.$$;
      // Create sheet modal
      instance.picker = instance.$f7.picker.create({
        inputEl: '#demo-picker-date',
        openIn: 'auto',
        renderToolbar() {
          return '<div class="toolbar">' +
            '<div class="toolbar-inner">' +
              '<div class="left">' +
                '<a href="#" class="link sheet-close popover-close">Avbryt</a>' +
              '</div>' +
              '<div class="right">' +
                '<a href="#" class="link sheet-save">Spara</a>' +
              '</div>' +
            '</div>' +
          '</div>';
        },
        rotateEffect: false,
        value,
        formatValue(values, displayValues) {
          return displayValues[0] + '.' + values[1];
        },
        cols,
        on: {
          open(picker) {
            picker.$el.find('.sheet-save').on('click', () => {
              console.log('Saving value')
              instance.setParameter(parameter, picker.getValue())
              picker.close()
            })
          },
          close(picker) {
            console.log('Closing')
          }
        }
      })
      // Open it
      instance.picker.open()
    }
  }
}
</script>
<style scoped>
  .bottom-bar {
    width: 100%;
  }

  .md .bottom-bar-spacer {
    padding-top: 42px;
  }

  .md .bottom-bar {
    position: fixed;
    width: 100%;
    bottom: 48px !important; 
    top: auto !important; 
    background: #fff;
    color: #000;
    z-index: 20;
  }

  .md .bottom-bar .list {
    margin: 0;
  }

  .md .toolbar-bottom-md ~ .page-content {
    padding-bottom: 77px !important;
  }

  .md .bottom-bar:after {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 100%;
    height: 10px;
    top: auto;
    pointer-events: none;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.3)), color-stop(40%, rgba(0, 0, 0, 0.1)), color-stop(50%, rgba(0, 0, 0, 0.05)), color-stop(80%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
}
</style>