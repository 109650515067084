<template>
  <f7-page>
    <navbar :title="unit.name || 'Ny enhet'" back-link="Tillbaka"></navbar>
    <f7-block :strong="values.length > 0">
      <div id="chartContainer" style="height: 300px; width: 100%;"></div>
    </f7-block>
    <f7-block>
      <f7-row>
        <f7-button icon-f7="arrow_left" @click="stepBack"></f7-button>
        <f7-button fill raised popup-open="#setup-chart">
          Inställningar
        </f7-button>
        <f7-button :class="{ hidden: isPresentTime }" icon-f7="arrow_right" @click="stepForward"></f7-button>
      </f7-row>
    </f7-block>
    <f7-block>
      
    </f7-block>
    <!-- Popup -->
    <f7-popup id="setup-chart">
      <f7-view>
        <f7-page>
          <f7-navbar title="Historik">
            <f7-nav-right>
              <f7-link popup-close>Stäng</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <f7-button fill raised @click="getAdvancedData" :disabled="!validSelection">Hämta data</f7-button>
          </f7-block>
          <f7-list>
            <!-- <f7-list-item v-for="parameter in parameters" :key="parameter.modbus">
              <span>{{ parameter.name }}</span>
              <f7-toggle :checked="isSelected(parameter.name)" @toggle:change="toggleParameter(parameter.name)"></f7-toggle>
            </f7-list-item> -->
            <f7-list-input label="Från" type="text" :value="fromDate" placeholder="Välj datum" id="from-date" readonly></f7-list-input>
            <f7-list-input label="Klockslag" type="text" :value="fromTime" placeholder="Välj tid" id="from-time" readonly></f7-list-input>
            <f7-list-input label="Varaktighet" type="text" :value="durationDisplay" placeholder="Välj varaktighet" id="chart-duration" readonly></f7-list-input>
          </f7-list>
          <f7-block-title>Parametrar</f7-block-title>
          <f7-list>
            <f7-list-item 
              v-for="parameter in parameters"
              :key="parameter.modbus" checkbox
              :value="parameter.name"
              :checked="selectedParameters.indexOf(parameter.name) >= 0"
              :title="parameter.name"
              @change="checkParameter"
            >
            </f7-list-item>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<script>
import CanvasJS from '../js/canvasjs.min.js'

import { formatDate, formatTime, valuesBetween, paddedValuesBetween } from '../js/helpers.js'

const durationSingular = {
  hours: 'timme',
  days: 'dag',
  weeks: 'vecka'
}
const singularDisplay = Object.values(durationSingular)

const durationPlural = {
  hours: 'timmar',
  days: 'dagar',
  weeks: 'veckor'
}
const pluralDisplay = Object.values(durationPlural)

function getDate (date, time) {
  if (!date || !time) {
    return false
  }

  let year = parseInt(date.split('-')[0])
  let month = parseInt(date.split('-')[1])
  let day = parseInt(date.split('-')[2])
  let hour = parseInt(time.split(':')[0])
  let minute = parseInt(time.split(':')[1])

  return new Date(year, month - 1, day, hour, minute, 0)
}

function dateOffset (date, offset, unit) {
  if (unit === 'hours') {
    date.setHours(date.getHours() + offset)
  } else if (unit === 'days') {
    date.setDate(date.getDate() + offset)
  }

  return date
}

let evtSource 
export default {
  data() {
    return {
      device: {},
      parameters: [],
      selectedParameters: [],
      values: [],
      fromDate: '',
      fromTime: '',
      duration: [2, 'hours']
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    unitId() {
      return this.$f7route.params.unitId
    },
    validSelection () {
      return this.selectedParameters.length && this.fromDate && this.fromTime
    },
    unit() {
      return this.$f7.data.units[this.unitId] || {}
    },
    durationDisplay() {
      const unit = this.duration[0] === 1 ? durationSingular[this.duration[1]] : durationPlural[this.duration[1]]
      return `${this.duration[0]} ${unit}`
    },
    isPresentTime() {
      if (!this.fromDate || !this.fromTime) {
        return true
      }
      const currentDate = new Date()
      const startDate = getDate(this.fromDate, this.fromTime)
      const endDate = dateOffset(startDate, this.duration[0], this.duration[1]).setSeconds(59)
      return endDate >= currentDate
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this

    const date = new Date
    date.setHours(date.getHours() - 2)
    this.fromDate = formatDate(date)
    this.fromTime = formatTime(date)

    this.ensureDevice().then(() => {
      if (this.device.meta && this.device.meta.value) {
        this.selectedParameters.push(this.device.meta.value)
        this.getAdvancedData()
      }
    })

    const fromDatePicker = app.calendar.create({
      inputEl: '#from-date',
      monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      dayNames: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
      dayNamesShort: ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'],
      firstDay: 0,
      maxDate: new Date(),
      dateFormat: 'yyyy-mm-dd',
      closeOnSelect: true,
      on: {
        close(calendar) {
          const value = calendar.getValue()
          if (value) {
            instance.fromDate = formatDate(value[0])
          }
        },
        closed(calendar) {
          fromTimePicker.open()
        }
      }
    })

    const fromTimePicker = app.picker.create({
      inputEl: '#from-time',
      openIn: 'auto',
      formatValue(values, displayValues) {
        return values[0] + ':' + values[1]
      },
      renderToolbar() {
        return '<div class="toolbar">' +
          '<div class="toolbar-inner">' +
            '<div class="left">' +
              '<a href="#" class="link sheet-close popover-close">Avbryt</a>' +
            '</div>' +
            '<div class="right">' +
              '<a href="#" class="link sheet-save">Välj</a>' +
            '</div>' +
          '</div>' +
        '</div>';
      },
      rotateEffect: false,
      cols: [
        {
          textAlign: 'right',
          values: paddedValuesBetween(0, 23)
        },
        {
          divider: true,
          content: ':'
        },
        {
          textAlign: 'left',
          values: paddedValuesBetween(0, 59)
        },
      ],
      on: {
        open(picker) {
          const values = instance.fromTime.split(':')
          picker.setValue(values, 0)
          picker.$el.find('.sheet-save').on('click', () => {
            const value = picker.getValue()
            if (value) {
              instance.fromTime = value.join(':')
            }
            picker.close()
          })
        }
      }
    })

    const durationPicker = app.picker.create({
      inputEl: '#chart-duration',
      openIn: 'auto',
      formatValue(values, displayValues) {
        return values[0] + ' ' + displayValues[1]
      },
      renderToolbar() {
        return '<div class="toolbar">' +
          '<div class="toolbar-inner">' +
            '<div class="left">' +
              '<a href="#" class="link sheet-close popover-close">Avbryt</a>' +
            '</div>' +
            '<div class="right">' +
              '<a href="#" class="link sheet-save">Välj</a>' +
            '</div>' +
          '</div>' +
        '</div>';
      },
      rotateEffect: false,
      cols: [
        {
          textAlign: 'right',
          values: valuesBetween(1, 12),
          onChange(picker, value, displayValue) {
            const oldValues = picker.getValue()
            if (picker.cols[1].replaceValues) {
              if (value > 1 && oldValues[0] == 1) {
                picker.cols[1].replaceValues(['hours', 'days', 'weeks'], pluralDisplay)
                picker.setValue([value, oldValues[1]], 0)
              } else if (value == 1 && oldValues[0] > 1) {
                picker.cols[1].replaceValues(['hours', 'days', 'weeks'], singularDisplay)
                picker.setValue([value, oldValues[1]], 0)
              }
            }
          }
        },
        {
          textAlign: 'left',
          displayValues: instance.duration[0] === 1 ? singularDisplay : pluralDisplay,
          values: ['hours', 'days', 'weeks']
        },
      ],
      on: {
        open(picker) {
          const values = instance.duration
          picker.setValue(values, 0)
          picker.$el.find('.sheet-save').on('click', () => {
            const value = picker.getValue()
            if (value) {
              console.log(`Setting duration to: ${value}`)
              instance.duration = [parseInt(value[0]), value[1]]
              instance.displayDuration = [parseInt(value[0]), value[1]]
            }
            picker.close()
          })
        }
      }
    })
  },
  methods: {
    ensureDevice() {
      return new Promise((resolve, reject) => {
        if (this.$f7.data.devices[this.unit.deviceId]) {
          this.device = this.$f7.data.devices[this.unit.deviceId]
          this.parameters = this.device.loggedParameters || []
          resolve()
        } else {
          this.getDevice().then(() => {
            resolve()
          })
        }
      })
    },
    step(offset, unit) {
      let date = getDate(this.fromDate, this.fromTime)

      date = dateOffset(date, offset, unit)

      this.fromDate = formatDate(date)
      this.fromTime = formatTime(date)

      this.getAdvancedData()
    },
    stepBack() {
      let [time, unit] = this.duration
      unit = unit === 'weeks' ? 'days' : unit
      time = unit === 'weeks' ? time * 7 : time
      this.step(-time, unit)
    },
    stepForward() {
      let [time, unit] = this.duration
      unit = unit === 'weeks' ? 'days' : unit
      time = unit === 'weeks' ? time * 7 : time
      this.step(time, unit)
    },
    isSelected(parameterName) {
      return !!this.selectedParameters.find(parameter => parameter === parameterName)
    },
    checkParameter(event) {
      const value = event.target.value
      if (event.target.checked) {
        this.selectedParameters.push(value)
      } else {
        this.selectedParameters.splice(this.selectedParameters.indexOf(value), 1)
      }
    },
    toggleParameter(parameterName) {
      const paramIndex = this.selectedParameters.findIndex(parameter => parameter === parameterName)
      if (paramIndex >= 0) {
        this.selectedParameters.splice(paramIndex, 1)
        return
      }
      this.selectedParameters.push(parameterName)
    },
    getDevice () {
      return new Promise((resolve, reject) => {
        const app = this.$f7
        const instance = this
        app.dialog.preloader('Laddar..')

        this.$request.json(`/api/devices/${this.unit.deviceId}`, (data, status) => {
          app.dialog.close()
          if (data.device && data.device.groups) {
            app.data.devices[this.unit.deviceId] = data.device
            this.device = data.device
            instance.parameters = data.device.loggedParameters || []
          }
          resolve()
        }, (xhr, status) => {
          app.dialog.close()
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          }
          reject()
        })
      })
    },
    selectParameters(element) {
      const selected = []
      for (let i = 0, iLen=element.options.length; i<iLen; i++) {
        const opt = element.options[i]

        if (opt.selected) {
          selected.push(opt.value)
        }
      }
      this.selectedParameters = selected
    },
    getBasicData() {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      this.$request.json(`/api/units/${this.unitId}/history`, (data, status) => {
        app.dialog.close()
        if (data.history) {
          this.values = data.history
          this.renderChart()
        }
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    },
    getAdvancedData() {
      const app = this.$f7
      const instance = this
      app.dialog.preloader('Laddar..')

      const body = {
        from: `${this.fromDate} ${this.fromTime}`,
        timezoneOffset: (new Date).getTimezoneOffset(),
        duration: this.duration,
        parameters: this.selectedParameters
      }

      this.$request.postJSON(`/api/units/${this.unitId}/history`, body, (data, status) => {
        app.dialog.close()
        app.popup.close()
        if (data.history) {
          this.values = data.history
          this.renderChart()
        }
      }, (xhr, status) => {
        app.dialog.close()
        if (status === 401) {
          app.dialog.alert('Du har inte åtkomst')
        }
      })
    },
    renderChart(basic) {
      const app = this.$f7
      const instance = this

      const axisY = {
        labelFontFamily: 'Roboto, Noto, Helvetica, Arial, sans-serif',
        // suffix: ' °C',
        gridColor: '#ddd',
        includeZero: false,
      }

      const data = []
      
      if (basic) {
        const valuesData = this.values.map((item) => {
          minValue = minValue ? Math.min(minValue, item.value) : item.value
          maxValue = maxValue ? Math.max(maxValue, item.value) : item.value
          return {
            x: new Date(item.time),
            y: parseFloat(item.value)
          }
        })

        axisY.minimum = parseInt(minValue) - 1
        axisY.maximum = parseInt(maxValue) + 1

        data.push({
          type: 'line',
          label: 'Value',
          name: 'Value',
          legendText: 'Value',
          showInLegend: true,
          type: 'line', //isBinary ? 'stepLine' : 'line'
          // axisYType: isBinary ? 'secondary' : 'primary',
          xValueFormatString: 'HH:mm',
          yValueFormatString: '###.0', // isBinary ? '0' : '###.0'
          dataPoints: valuesData,
          markerSize: 0,
        })
      } else {
        const dataseries = {}
        this.values.forEach((item) => {
          const time = new Date(item.time)
          Object.keys(item).filter(key => key !== 'time').forEach((key) => {
            if (!dataseries[key]) dataseries[key] = []
            dataseries[key].push({
              x: time,
              y: parseFloat(item[key])
            })
          })
        })

        Object.keys(dataseries).forEach((key) => {
          data.push({
            label: key,
            name: key,
            legendText: key,
            showInLegend: true,
            type: 'line', //isBinary ? 'stepLine' : 'line'
            // axisYType: isBinary ? 'secondary' : 'primary',
            xValueFormatString: 'D/M HH:mm',
            yValueFormatString: '###.0', // isBinary ? '0' : '###.0'
            dataPoints: dataseries[key],
            markerSize: 0,
          })
        })
      }

      const chart = new CanvasJS.Chart('chartContainer', {
        backgroundColor: 'transparent',
        theme: 'light2',
        data,
        toolTip: {
          fontFamily: 'Roboto, Noto, Helvetica, Arial, sans-serif',
          shared: true
        },
        legend: {
          fontFamily: 'Roboto, Noto, Helvetica, Arial, sans-serif',
          horizontalAlign: 'center',
          verticalAlign: 'bottom',
          fontSize: 15,
          cursor: 'pointer',
          itemclick (e) {
            if (typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
              e.dataSeries.visible = false
            } else {
              e.dataSeries.visible = true
            }
            e.chart.render()
          }
        },
        axisX: {
          valueFormatString: 'HH:mm',
          labelFontSize: 15,
          labelFontFamily: 'Roboto, Noto, Helvetica, Arial, sans-serif',
          gridColor: '#ddd',
          crosshair: {
            enabled: true,
            color: '#888'
          }
        },
        axisY
      })

      chart.render()
    }
  }
}

const historyToData = function historyToData (history) {
  let data = {}

  history.forEach((item) => {
    Object.keys(item).forEach((prop) => {
      if (prop !== 'time' && prop !== 'evap') {
        if (!data[prop]) data[prop] = []
        data[prop].push({
          x: item['time'],
          y: item[prop]
        })
      }
    })
  })

  data = Object.keys(data).map((prop) => {
    const name = prop.charAt(0).toUpperCase() + prop.slice(1)
    const isBinary = ['comp', 'def', 'door'].indexOf(prop) >= 0
    return {
      label: name,
      name: name,
      legendText: name,
      showInLegend: true,
      type: isBinary ? 'stepLine' : 'line',
      axisYType: isBinary ? 'secondary' : 'primary',
      xValueFormatString: 'HH:mm',
      yValueFormatString: isBinary ? '0' : '###.0',
      dataPoints: data[prop]
    }
  })

  return data
}

</script>