<template>
  <f7-navbar bg-color="primary" text-color="white" :title="title" :back-link="backLink">
    <slot></slot>
  </f7-navbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    backLink: {
      type: String,
      default: null
    }
  }
}
</script>