<template>
  <f7-page>
    <navbar title="Användare" back-link="Tillbaka"></navbar>
    <f7-block>
      <f7-list>
        <f7-list-item header="Förnamn" :title="user.firstname"></f7-list-item>
        <f7-list-item header="Efternamn" :title="user.lastname"></f7-list-item>
        <f7-list-item header="Epost" :title="user.email"></f7-list-item>
      </f7-list>
    </f7-block>
  </f7-page>
</template>

<script>

let evtSource 
export default {
  data() {
    return {}
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    userId() {
      return this.$f7route.params.userId
    },
    submitText() {
      return !this.saving ? 'Spara' : 'Sparar..'
    },
    user() {
      return this.$f7.data.users.find(user => user.id === parseInt(this.userId))
    }
  },
  mounted() {
    const app = this.$f7
    const instance = this
    
  },
  methods: {}
}

</script>
<style>
 textarea {
   white-space:pre;
 }
</style>
