<template>
  <f7-page login-screen>
    <f7-block strong>
      <img src="../assets/connect_logo.svg" style="width: 100%;">
    </f7-block>
    <f7-block strong>
      <p>Välkommen, skapa ett konto eller logga in för att börja använda tjänsten.</p>
    </f7-block>
    <f7-block>
      <f7-button fill raised href="/login/">Logga in</f7-button>
    </f7-block>
    <f7-block>
      <f7-button href="/register/">Skapa konto</f7-button>
    </f7-block>
  </f7-page>
</template>
<script>
export default {
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    }
  },
  mounted() { //beforeCreate
    console.log('Mounted home!')
    if(this.$f7.data.user && this.$f7.data.user.id) {
      this.$f7router.navigate('/enheter/')
    }
  }
}
</script>
