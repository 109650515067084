<template>
  <f7-page ptr @ptr:refresh="getUnit">
    <navbar :title="unit.name || 'Ny enhet'" back-link="Tillbaka"></navbar>
    <f7-block>
      <f7-list>
        <!-- <f7-list-item header="ID" :title="unit.id"></f7-list-item> -->
        <f7-list-item v-if="unit.customer" header="Kund" :title="unit.customer"></f7-list-item>
        <f7-list-item v-if="unit.site" header="Anläggning" :title="unit.site"></f7-list-item>
        <f7-list-item v-if="unit.city" header="Stad" :title="unit.city"></f7-list-item>
        <f7-list-item v-if="unit.type" header="Aggregat" :title="unit.type"></f7-list-item>
        <f7-list-item v-if="unit.serialNumber" header="Serienr" :title="unit.serialNumber"></f7-list-item>
        <!-- <f7-list-item header="Status" :title="unitStatus"></f7-list-item> -->
        <!-- <f7-list-item header="Enhet" :title="deviceStatus"></f7-list-item> -->
        <f7-list-item header="Type" :title="deviceType"></f7-list-item>
      </f7-list>
    </f7-block>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button big fill raised popup-open="#edit-unit">
            <f7-icon material="create" size="20"></f7-icon>
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button big fill raised :href="(`/enheter/${unit.id}/live`)">
            <f7-icon material="play_arrow" size="20"></f7-icon>
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button big fill raised :href="(`/enheter/${unit.id}/parametrar`)">
            <f7-icon material="settings" size="20"></f7-icon>
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button big fill raised :href="(`/enheter/${unit.id}/historik`)">
            <f7-icon material="show_chart" size="20"></f7-icon>
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button big fill raised :href="(`/enheter/${unit.id}/access`)">
            <f7-icon material="vpn_key" size="20"></f7-icon>
          </f7-button>
        </f7-col>
        <f7-col v-if="isAdmin">
          <f7-button big fill raised :href="(`/enheter/${unit.id}/logg`)">
            <f7-icon material="library_books" size="20"></f7-icon>
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
    <template v-if="isAdmin">
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-button big fill raised :href="(`/enheter/${unit.id}/firmware`)">
              <f7-icon material="memory" size="25"></f7-icon> Firmware
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </template>
    <!-- Popup -->
    <f7-popup id="edit-unit">
      <f7-view>
        <f7-page>
          <f7-navbar title="Redigera enhet">
            <f7-nav-right>
              <f7-link popup-close>Stäng</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-list form>
            <f7-list-input label="Namn" type="text" placeholder="ex. Ankomstfrys" :value="unit.name" @input="unit.name = $event.target.value"></f7-list-input>
            <f7-list-input label="Ägare" type="text" placeholder="ex. TLS Container" :value="unit.customer" @input="unit.customer = $event.target.value"></f7-list-input>
            <f7-list-input label="Anläggning" type="text" placeholder="ex. ICA Helsingborg" :value="unit.site" @input="unit.site = $event.target.value"></f7-list-input>
            <f7-list-input label="Stad" type="text" placeholder="ex. Helsingborg" :value="unit.city" @input="unit.city = $event.target.value"></f7-list-input>
            <f7-list-input label="Aggregat" type="text" placeholder="ex. CFA30" :value="unit.type" @input="unit.type = $event.target.value"></f7-list-input>
            <f7-list-input label="Serienr" type="text" placeholder="ex. 18.2658" :value="unit.serialNumber" @input="unit.serialNumber = $event.target.value"></f7-list-input>
            <f7-block>
              <f7-button fill raised @click="updateUnit" :disabled="saving">{{ submitText }}</f7-button>
            </f7-block>
          </f7-list>
          <template v-if="isAdmin">
            <f7-block>
              <f7-list>
                <f7-list-item header="Ägare" :title="ownerText"></f7-list-item>
              </f7-list>
              <f7-row>
                <f7-col>
                  <f7-button raised @click="restartUnit">Restart</f7-button>
                </f7-col>
                <f7-col>
                  <f7-button raised @click="updateUnitFirmware">Update FW</f7-button>
                </f7-col>
              </f7-row>
            </f7-block>
            <f7-block>
              <f7-row>
                <f7-col>
                  <f7-button raised @click="resetUnitEDF">Reset EDF</f7-button>
                </f7-col>
                <f7-col>
                  <f7-button raised @click="removeOwner">Remove Owner</f7-button>
                </f7-col>
              </f7-row>
            </f7-block>
          </template>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<script>
import unitMixin from '../mixins/unit'

let evtSource 
export default {
  mixins: [unitMixin],
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    },
    ownerText() {
      return this.unit && this.unit.owner ? this.unit.owner : 'Ingen ägare'
    },
    submitText() {
      return !this.saving ? 'Spara' : 'Sparar..'
    },
    unitStatus() {
      return this.unit.online ? 'Online' : 'Offline'
    },
    deviceStatus() {
      return this.unit.deviceOnline ? 'Online' : 'Offline'
    },
    deviceType() {
      if (this.unit.deviceName) {
        return `${this.unit.deviceName} (${this.unit.deviceModel})`
      }
      return 'Ingen enhet ansluten'
    }
  },
  mounted() {
    console.log('Unit details mounted!')
    this.getUnit()
  },
  methods: {
    restartUnit() {
      const app = this.$f7
      this.$request.get(`/api/units/${this.unitId}/restart`, (data, status) => {
        app.dialog.alert('Enheten startar om!')
      })
    },
    resetUnitEDF() {
      const app = this.$f7
      this.$request.get(`/api/units/${this.unitId}/resetedf`, (data, status) => {
        app.dialog.alert('Enhetens EDF uppdateras!')
      })
    },
    updateUnitFirmware() {
      const app = this.$f7
      this.$request.get(`/api/units/${this.unitId}/updatefw`, (data, status) => {
        app.dialog.alert('Enheten uppdateras!')
      })
    },
    removeOwner() {
      this.$request({
        url: `/api/units/${this.unitId}`,
        method: 'PUT',
        dataType: 'json',
        data: {
          owner: null
        },
        success(data, status) {
          instance.unit = data.unit
          app.dialog.alert('Ägare avregistrerad!')
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          }
        }
      })
    },
    updateUnit() {
      const instance = this
      const app = this.$f7
      instance.saving = true

      const updatedUnit = {
        name: this.unit.name,
        site: this.unit.site,
        city: this.unit.city,
        customer: this.unit.customer,
        type: this.unit.type,
        serialNumber: this.unit.serialNumber
      }

      this.$request({
        url: `/api/units/${this.unitId}`,
        method: 'PUT',
        dataType: 'json',
        data: updatedUnit,
        success(data, status) {
          instance.unit = data.unit
        },
        error(xhr, status) {
          if (status === 401) {
            app.dialog.alert('Du har inte åtkomst')
          } else {
            app.dialog.alert('Någonting gick fel', 'Hoppsan!')
          }
        },
        complete(xhr, status) {
          instance.saving = false
          app.popup.close()
        }
      })
    }
  }
}

</script>

<style>
  .button i.icon {
    bottom: 1px;
  }
</style>