<template>
  <f7-page>
    <navbar title="Modeller">
      <f7-nav-right v-if="isAdmin">
        <f7-link panel-open="admin">
          <f7-icon f7="gear"/>
        </f7-link>
      </f7-nav-right>
    </navbar>
    <f7-list media-list>
      <f7-list-item v-for="device in devices" 
        :key="device.id" 
        :title="device.name" 
        :footer="device.model" 
        :link="(`/modeller/${device.id}`)"
      >
        <f7-icon slot="media" f7="layers"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      devices: []
    }
  },
  computed: {
    isAdmin() {
      return this.$f7.data.user && this.$f7.data.user.admin
    }
  },
  mounted() {
    const instance = this
    const app = this.$f7
    app.dialog.preloader('Laddar..')

    this.$request.json('/api/devices', (data, status) => {
      app.dialog.close()
      if (data.devices) {
        this.devices = data.devices
      }
    }, (data, status) => {
      setTimeout(() => {
        app.dialog.close()
        this.$f7router.navigate('/login/', { pushState: false, context: { redirectedFrom: this.$f7route.url } })
      }, 300)
    })
  }
}
</script>