function padStart (number, digits) {
  return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
}

function formatDate (date) {
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  let year = date.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

function formatTime (date) {
  return `${padStart(date.getHours(), 2)}:${padStart(date.getMinutes(), 2)}`
}

function formatDateTime (date) {
  return `${formatDate(date)} ${formatTime(date)}`
}

function valuesBetween (min, max) {
  const arr = []
  for (let i = min; i <= max; i++) {
    arr.push(i)
  }
  return arr
}

function paddedValuesBetween (min, max) {
  const arr = []
  for (let i = min; i <= max; i++) {
    if (i < 10) i = '0' + i
    arr.push(i)
  }
  return arr
}

export { formatDate, formatTime, formatDateTime, valuesBetween, paddedValuesBetween }
